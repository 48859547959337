<div class="songs-list">

    <div *ngIf="showEmptyNotice && songs.length === 0" class="empty-songs-list">
        {{ emptyListMessage }}
    </div>

    <div *ngFor="let songItem of songItems; trackBy: trackById" [attr.data-pw]="songItem.id">
        <a [routerLink]="songItem.link" [title]="songItem.titleAttribute">
            {{ songItem.title }}
        </a>
        <a *ngIf="songItem.showPrimaryCollectionLink" class="dark-pill primary-collection-link"
           [routerLink]="songItem.primaryCollectionLink"
           [title]="i18n.songPrimaryCollectionLinkTitle"
        >
            {{ songItem.primaryCollectionName }}
        </a>
    </div>
</div>
