@if (!loaded && (isLoadingIndicatorVisible$|async)) {
  <div class="indicator">
    <div class="message">{{ i18n.loadingMessage }}</div>
    <gt-loading-indicator></gt-loading-indicator>
  </div>
}

@if (loaded && song && songDetails && collection) {
  <div itemscope itemtype="http://schema.org/MusicRecording">
    <meta itemprop="name" [content]="song.title" />
    <div itemprop="byArtist" itemscope [attr.itemtype]="schemaItemArtistType" *ngIf="!!schemaItemArtistType">
      <meta itemprop="name" [content]="schemaItemArtistName" />
    </div>

    @if (mode === 'print-mode') {
      <gt-song-header [song]="song" [collection]="collection" [showControls]="false"></gt-song-header>
      <gt-song-text [song]="songDetails" [multiColumnMode]="false" [usePrintFontSize]="true"></gt-song-text>
      <gt-song-chords [songId]="song.id"></gt-song-chords>
    }

    @if (mode !== 'print-mode') {
      <gt-song-header [song]="song"
                      [collection]="collection"
                      [titleFormat]="mode === 'song-page-mode' ? 'song' : 'song-and-collection'"
                      [showCollectionLink]="showCollectionLink"
      ></gt-song-header>
      <gt-song-video [title]="song.title" [mediaLinks]="songDetails.mediaLinks" itemprop="video"></gt-song-video>
      <gt-song-text [song]="songDetails" itemprop="text"></gt-song-text>
      <gt-song-chords [songId]="song.id" showControls="true"></gt-song-chords>
    }
  </div>
}
