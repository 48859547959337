<div class="container">

  <div class="title">{{ i18n.title }}</div>

  <div *ngFor="let step of visibleSteps; trackBy: trackByUrl" class="list-item">
    <a routerLink="{{step.url}}" (click)="popover.close()">
      @if (isCollectionMount(step.url)) {
        »<span class="dark-pill collection">{{ step.name }}</span>
      } @else {
        {{ step.name }}
        <span *ngIf="step.collection" class="dark-pill collection">{{ step.collection }}</span>
      }
    </a>
  </div>

</div>
